<template>
  <div class="container">
    <van-loading
      class="loading"
      color="#9d1d22"
      text-color="#9d1d22"
      v-if="loading"
      size="24px"
      >加载中...</van-loading
    >
    <div v-if="loading == false">
      <van-sticky>
        <van-nav-bar
          :title="coursesSuccess?courses.title:''"
          left-arrow
          @click-left="$router.back()"
        >
          <template #right>
            <div @click="toHome()">
              <van-icon name="wap-home-o" size="18" />
              <div style="float: right; margin-left: 5px; font-size: 15px">
                首页
              </div>
            </div>
          </template>
        </van-nav-bar>
      </van-sticky>
      <div v-if="coursesSuccess">
        <div class="detail">
          <VideoAudioPlayer
            :courses="courses"
            :browsingLogType="7"
          ></VideoAudioPlayer>

          <van-row>
            <van-col span="24">
              <div class="acontent" v-html="courses.detailsHtml"></div>
            </van-col>
          </van-row>
        </div>

        <div
          class="collectionlist"
          v-if="collections.preCourse || collections.nextCourse"
        >
          <div
            style="
              font-size: 15px;
              color: rgba(0, 0, 0, 0.5);
              line-height: 20px;
            "
          >
            收录于合集
          </div>

          <div class="collectionbox">
            <div
              class="collectionitem"
              @click="HandlerShowCollection(collection.id.toString())"
              v-for="(collection, index) in collections.collectionsList"
              :key="index"
            >
              <van-tag
                color="rgb(255 242 219)"
                text-color="#9d1d22"
                size="large"
                >#{{ collection.name }}# </van-tag
              ><span class="collectioncount">{{ collection.count }}</span>
            </div>
          </div>
          <div style="padding: 10px 0px">
            <van-row v-if="collections.preCourse && collections.nextCourse">
              <van-col span="11" @click="toDetail(collections.preCourse.id)">
                <div>
                  <div style="line-height: 18px">
                    <i class="van-icon van-icon-arrow-left van-nav-bar__arrow"
                      ><!----></i
                    >
                    上一篇
                  </div>
                  <div style="font-size: 16px">
                    {{ collections.preCourse.title }}
                  </div>
                </div>
              </van-col>
              <van-col span="2"></van-col>
              <van-col span="11" @click="toDetail(collections.nextCourse.id)">
                <div style="text-align: right">
                  下一篇
                  <i
                    data-v-4498f7b0=""
                    class="van-icon van-icon-arrow van-cell__right-icon"
                    ><!----></i
                  >
                </div>
                <div style="font-size: 16px">
                  {{ collections.nextCourse.title }}
                </div>
              </van-col>
            </van-row>
            <van-row v-else-if="collections.preCourse">
              <van-col span="2"></van-col>
              <van-col span="22" @click="toDetail(collections.preCourse.id)">
                <div>
                  <div style="line-height: 18px">
                    <i class="van-icon van-icon-arrow-left van-nav-bar__arrow"
                      ><!----></i
                    >
                    上一篇
                  </div>
                  <div style="font-size: 16px">
                    {{ collections.preCourse.title }}
                  </div>
                </div>
              </van-col>
            </van-row>
            <van-row v-else-if="collections.nextCourse">
              <van-col span="2"></van-col>
              <van-col span="22" @click="toDetail(collections.nextCourse.id)">
                <div>
                  <div>
                    下一篇
                    <i
                      data-v-4498f7b0=""
                      class="van-icon van-icon-arrow van-cell__right-icon"
                      ><!----></i
                    >
                  </div>
                  <div style="font-size: 16px">
                    {{ collections.nextCourse.title }}
                  </div>
                </div>
              </van-col>
            </van-row>
          </div>
        </div>
        <van-popup
          v-model="showCollectionList"
          closeable
          position="bottom"
          :style="{ height: '90%' }"
        >
          <div style="padding: 50px 10px 20px 10px">
            <van-cell-group>
              <van-cell
                v-for="item in CollectionList"
                :key="item.id"
                @click="toDetail(item.id.toString())"
                is-link
              >
                <template #title>
                  <span class="custom-title">{{ item.title }}</span>
                </template>
              </van-cell>
            </van-cell-group>
          </div>
        </van-popup>

        <commentScore :itemId="courses.id" itemType="3"></commentScore>
        <comment :CommentPage="CommentPage"></comment>
      </div>
      <div v-if="coursesSuccess == false">
        <van-empty
          description="参数错误，未找到对应数据"
          image="/image/empty-image-default.png"
        />
      </div>
    </div>
  </div>
</template>

<script>
import VideoAudioPlayer from "../components/videoAudioPlayer";
import {
  getCourseInfo,
  GetCourseCollectionsShow,
  getCourseList,
} from "@/api/school";
import Comment from "../components/comment";
import CommentScore from "../components/commentScore";

export default {
  name: "zncoursedetail",
  data() {
    return {
      loading: true,
      courses: {
        title: "",
      },
      coursesSuccess: true,
      CommentPage: {
        current: 1,
        pageSize: 10,
        filterData: {
          itemId: "",
          itemType: 3,
        },
      },
      collections: {},
      showCollectionList: false,
      CollectionList: [],
      collectionpara: {
        current: 1,
        pageSize: 200,
        sorterType: 0,
        romType: 1,
        filterData: {
          title: "",
          schoolClass: 0,
          schoolClass2: 0,
          typeId: 0,
          isS7: false,
          collections: "",
        },
      },
    };
  },
  components: {
    Comment,
    CommentScore,
    VideoAudioPlayer,
  },
  created() {
    this.initData();
    this.onLoad();
  },
  watch: {
    $route(to, from) {
      //监听路由是否变化
      if (to.path == "/course/detail") {
        if (to.query != from.query) {
          this.loading = true;
          this.initData();
          this.onLoad();
        }
      }
    },
  },
  mounted() {},
  computed: {
    player() {
      return this.$refs.videoPlayer.player;
    },
  },
  beforeDestroy() {
    this.dobeforeDestroy();
  },
  deactivated() {
    this.dobeforeDestroy();
  },
  methods: {
    toHome() {
      this.$router.replace({ path: "/" });
    },
    toDetail(id) {
      this.$router.push({
        path: "/course/detail",
        query: { para: id },
      });
    },
    async HandlerShowCollection(id) {
      this.CollectionList = [];
      this.collectionpara.filterData.collections = id;
      let aresult = await getCourseList({ ...this.collectionpara });

      this.CollectionList.push(...aresult.data.data);
      this.showCollectionList = true;
    },

    initData() {},

    async onLoad() {
      let para = this.$route.query.para;
      let aresult = await getCourseInfo(para); //({ ...this.page })
      this.videoplayTime = 0;

      this.videoPlayCount = 0;

      this.coursesSuccess = aresult.data.success;

      this.courses = aresult.data.data;
      if (aresult.data.success) {
        document.title = this.courses.title;

        if (
          this.courses.collections != undefined &&
          this.courses.collections != ""
        ) {
          let collectionResult = await GetCourseCollectionsShow(
            this.courses.id
          );

          this.collections = collectionResult.data.data;
        }

        this.CommentPage.filterData.itemId = this.courses.id;
      }
      this.loading = false;
    },

    s_to_hs(s) {
      //计算分钟
      //算法：将秒数除以60，然后下舍入，既得到分钟数
      var h;
      h = Math.floor(s / 60);
      //计算秒
      //算法：取得秒%60的余数，既得到秒数
      s = s % 60;
      //将变量转换为字符串
      h += "";
      s += "";
      //如果只有一位数，前面增加一个0
      h = h.length == 1 ? "0" + h : h;
      s = s.length == 1 ? "0" + s : s;
      return h + ":" + s;
    },
  },
};
</script>


<style lang="less" scoped>
.loading {
  text-align: center;
}
.container {
  height: 100%;
  overflow-y: auto;
  box-sizing: border-box;
}
.detail {
  padding: 10px 10px 20px;
}
.znaudio {
  padding-left: 25px;
  padding-right: 25px;
  /deep/ .name {
    text-align: center;
    line-height: 120px;
  }
}
.acontent {
  /deep/ img {
    max-width: 100% !important;
    display: block;
    border: 0;
  }
  /deep/ video {
    max-width: 100% !important;
  }
  /deep/ p {
    padding: revert;
    margin: revert;
  }
}
.znvideo {
  /deep/ .vjs-poster {
    background-color: rgba(157, 29, 34, 0.9);
  }

  video::-internal-media-controls-download-button {
    display: none;
  }

  video::-webkit-media-controls-enclosure {
    overflow: hidden;
  }

  video::-webkit-media-controls-panel {
    width: calc(100% + 30px);
  }
}

.product-proptext {
  margin-left: 20px;
}

.collectionlist {
  margin: 0 0 16px;
  padding: 12px 16px;
  border-radius: 8px;
  background-color: #f7f7f7;
}
.collectionbox {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.collectionitem {
  margin-right: 12px;
  margin-top: 6px;
  position: relative;
  display: -ms-flexbox;
  display: flex;
}
.collectioncount {
  display: inline-block;
  color: rgba(0, 0, 0, 0.3);
  border-radius: 8px;
  font-size: 15px;
  line-height: 20px;
  margin-left: 4px;
}
</style>